import './App.css';
import Bio from './Bio';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-container">
          <Bio />
        </div>
        <Outlet />
      </header>
    </div>
  );
}

export default App;
