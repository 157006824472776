import './App.css'
import AppList from './AppList';
import Project from './Project';
import {getProjects} from './projects';

function Home() {
  const projects = getProjects();
  const projectKeys = Object.keys(projects);

  return (
    <>
    <div className="App-container">
      <AppList />
    </div>
    <div className="App-grid">
      <div className="App-col">
        {
          projectKeys.map((key, index) => {
            return (index % 2 === 1 || projects[key].image === undefined ? null : (
              <Project name={projects[key].name}
                image={projects[key].image} 
                center={projects[key].center ? projects[key].center : false}
                link={`/${key}`}
                tags={projects[key].tags}/>
            ))    
          })
        }
      </div>
      <div className="App-col">
      {
        projectKeys.map((key, index) => {
          return (index % 2 === 0 || projects[key].image === undefined ? null : (
            <Project name={projects[key].name}
              image={projects[key].image} 
              center={projects[key].center ? projects[key].center : false}
              link={`/${key}`}
              tags={projects[key].tags}/>
          ))    
        })
      }
      </div>
    </div>
    </>
  )
}

export default Home;