import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import ProjectPage from './ProjectPage';
import ScrollToTop from './ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Home />}/>
          <Route path="projects">
            <Route path=":projectId" element={<ProjectPage />} />
          </Route>
          <Route path="linkme-privacy-policy" element={<ProjectPage projectId="linkme-privacy" />} />
          <Route path="nocam-qr-privacy-policy" element={<ProjectPage projectId="nocam-privacy" />} />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
