import './Bio.css';
import biopic from './BioPic.png'

function Bio() {
  return (
    <div className='Bio-container'>
      <div className='Bio-col'>
        <span className="Bio-sectionHead">
          <a href={window.location.protocol + "//" + window.location.host}>
            Matt Horton
          </a>
        </span>
        <div className={`Bio-box Bio-box-image-center`} style={
            {backgroundImage: biopic ? `url(${biopic})` : null}
          }>
        </div>
      </div>
      <p className='Bio-text'>I've been an engineer, a <b>music technologist</b>, a <b>designer</b>, a <b>product manager</b>, and then an <b>engineer</b> again.<br/><br/>If you see <i>meaning in variety</i>, and share a <b>desire to make things that solve real world problems</b>, then <a href="mailto:matt@matthorton.live">send me an email</a>. Or <a href="https://twitter.com/mattahorton">tweet at me</a>.</p>
    </div>
  )
}

export default Bio;