import desktop from './Desktop.png';
import mobile from './Mobile.png';
import nocam from './NoCamQR.png';
import digcard from './DigitalCard.jpeg';
import sb from './SB.png';
import cafe from './Cafe.webp'

let projects = {
  "jacktrip-mobile": {
    file_name: "jacktrip-mobile.md",
    name: "JackTrip App",
    image: mobile,
    tags: ['React Native', 'iOS', 'Android'],
  },
  "jacktrip-desktop": {
    file_name: "jacktrip-desktop.md",
    name: "JackTrip Desktop",
    image: desktop,
    tags: ['Qt • QML', 'Windows', 'macOS', 'Linux'],
  },
  "nocamqr": {
    file_name: "nocamqr.md",
    name: "NoCam QR",
    image: nocam,
    tags: ['SwiftUI', 'iOS', 'Swift Playgrounds 4'],
    center: true,
  },
  "storyblocks": {
    file_name: "storyblocks.md",
    name: "Video Review App",
    image: sb,
    tags: ['Storyblocks', 'Content', 'Product', 'Design'],
  },
  "digital-card": {
    file_name: "digital-card.md",
    name: "Digital Card",
    image: digcard,
    tags: ['Capital One', 'Mobile', 'Product', 'Design'],
  },
  "local": {
    file_name: "local.md",
    name: "/Local",
    image: cafe,
    tags: ['Capital One', 'Product', 'Design', 'Web'],
  },
  "nocam-privacy": {
    file_name: "nocam-privacy.md",
    name: "NoCam QR Privacy Policy",
    tags: [],
  },
  "linkme-privacy": {
    file_name: "linkme-privacy.md",
    name: "LinkMe Privacy Policy",
    tags: [],
  },
};

export function getProjects() {
  return projects;
}