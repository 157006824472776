import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import './Project.css';
import './ProjectPage.css'
import { getProjects } from './projects';

function ProjectPage(props) {
  const [post, setPost] = useState(null);
  let params = useParams();
  let projectId = params.projectId ? params.projectId : props.projectId;
  let projects = getProjects();

  useEffect(() => {
    if (projects[projectId]) {
      import(`./markdown/${projects[projectId].file_name}`)
        .then(res => {
            fetch(res.default)
                .then(res => res.text())
                .then(res => setPost(res))
                .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    }
  });

  if (!projects[projectId]) {
    return (
      <main style={{ padding: "1rem" }}>
        <p>There's nothing here!</p>
      </main>
    );
  } else {
    return (
      <div className='ProjectPage'>
        <div className='Project'>
          <span className='Project-header'>
              {projects[projectId].name ? projects[projectId].name : 'Project'}
          </span>
          { projects[projectId].image ? (
            <div className={`Project-box ${projects[projectId].center ? 'Project-box-image-center' : 'Project-box-image-top'}`}
              style={
              {backgroundImage: projects[projectId].image ? `url(${projects[projectId].image})` : null}
              }>
              {projects[projectId].tags && <Tags tags={projects[projectId].tags} />}
            </div>
          ) : null}
        </div>
        <ReactMarkdown className="ProjectPage-text" remarkPlugins={[gfm]}>
          {post}
        </ReactMarkdown>
      </div>
    )
  }
}

function Tags(props) {
  return (
    <div className='Tags'>
      {props.tags.map(tag => <Tag text={tag} />)}
    </div>
  )
}

function Tag(props) {
  return (
    <span className='Tag'>
      {props.text}
    </span>
  )
}

export default ProjectPage;