import './AppList.css';
import nocam from './NoCamQR.png';
import linkme from './LM.png';
import jt from './JTIcon.webp';
import smule from './Smule.webp';
import scout from './Scout.webp';

function AppList(props) {
  return (
    <div className='AppList-container'>
      <div className='AppList-col'>
        <span className="AppList-sectionHead">Apps <span className="AppList-sectionSubHead AppList-subHeadWide">Where I've shipped code</span></span>
        <div className='AppList'>
          <AppItem image={nocam} link="https://apps.apple.com/jm/app/nocam-qr-scan-your-photos/id1604516482"/>
          <AppItem image={jt} link="https://apps.apple.com/us/app/jacktrip/id1587212170"/>
          <AppItem image={linkme} link="https://apps.apple.com/ng/app/linkme-rss-link-keyboard/id1042362090"/>
          <AppItem image={smule} link="https://apps.apple.com/us/app/smule-karaoke-singing-app/id509993510"/>
          <AppItem image={scout} link="https://apps.apple.com/us/app/scout-fdc/id952464824"/>
        </div>
      </div>
    </div>
  )
}

function AppItem(props) {
  return (
    <div className='AppItem'>
      <a aria-label="Matt Horton" href={props?.link ? props.link : "/"} style={
        {backgroundImage: props?.image ? `url(${props.image})` : `urls(https://picsum.photos/256/)`}
      }>
        <span aria-hidden="true"/>
      </a>
    </div>
  )
}

export default AppList;