import React from 'react';
import { Link } from 'react-router-dom';
import './Project.css'

function Project(props) {

  return (
    <div className='Project'>
      <span className='Project-header'>
        {props?.name ? props.name : 'Project'}
      </span>
      <Link to={`/projects${props.link}`} className={`Project-box ${props?.center ? 'Project-box-image-center' : 'Project-box-image-top'}`}
        style={
          {backgroundImage: props?.image ? `url(${props.image})` : null}
        }
      >
        {props?.tags && <Tags tags={props.tags} />}
      </Link>
    </div>
  )
}

function Tags(props) {
  return (
    <div className='Tags'>
      {props.tags.map(tag => <Tag text={tag} />)}
    </div>
  )
}

function Tag(props) {
  return (
    <span className='Tag'>
      {props.text}
    </span>
  )
}

export default Project;